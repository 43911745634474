import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Root from "../components/Root";

interface DefaultLayoutProps {
  pageTitle: string;
  className?: string;
  isFront?: boolean;
  isContact?: boolean;
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  className,
  isFront,
  isContact,
  pageTitle
}) => (
  <StaticQuery
    query={graphql`
      query OverrideDefaultLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      const siteTitle = data.site.siteMetadata.title;
      return (
        <Root className={className}>
          <Helmet title={isFront ? siteTitle : `${pageTitle} | ${siteTitle}`}>
            {isContact && (
              <script
                src="https://www.google.com/recaptcha/api.js"
                async
                defer
              ></script>
            )}
          </Helmet>
          <div className="page d-flex flex-column">
            <main className="main flex-grow-1">
              <div className="content">{children}</div>
            </main>
            <div className="boilerplate mt-5">
              <div className="container text-center">
                <div className="py-3">
                  <p className="m-0">© 2020 {data.site.siteMetadata.title}</p>
                </div>
              </div>
            </div>
          </div>
        </Root>
      );
    }}
  />
);

export default DefaultLayout;
